import 'core-js/stable';
// import 'core-js/actual';
import 'isomorphic-fetch';
import 'raf/polyfill';
import 'regenerator-runtime/runtime';
import 'moment/locale/pt-br';
import 'moment-timezone/builds/moment-timezone-with-data';

import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';

import { getSentryReplayIntegration, shouldUseSentry } from '@woovi/shared';

import Main from './App';
import { registerCardProcessingAll } from './card-provider/registerCardProcessingAll';
import { appendRoutes } from './router/routes';
import requiredRoutes from './router/routes/root/requiredRoutes';
import rootRoutes from './router/routes/root/rootRoutes';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/700.css';

// add all root routes to routes
appendRoutes(requiredRoutes);
appendRoutes(rootRoutes);

registerCardProcessingAll();

// setup sentry
if (shouldUseSentry) {
  const invalidSessionErrors = [/^Invalid session$/, /^Sessão inválida$/, /^Sesión inválida$/];

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: `woovi-admin@${process.env.npm_package_version}`,
    environment: process.env.APP_ENV,
    ignoreErrors: [...invalidSessionErrors],
    ...getSentryReplayIntegration(),
  });

  Sentry.getCurrentScope()?.setTag('git_commit', process.env.COMMIT_SHA);
}

if (process.env.GA_ID) {
  ReactGA.initialize(process.env.GA_ID);
}

const rootEl = document.getElementById('root');
const root = createRoot(rootEl);

const runApp = async (): Promise<void> => {
  if (root) {
    root.render(<Main />);
  } else {
    throw new Error('wrong rootEl');
  }

  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();

    const openpixSw = registrations.find((registration) =>
      registration.active?.scriptURL.includes('/openpix/sw.js'),
    );

    if (openpixSw) {
      openpixSw.unregister();
    }

    navigator.serviceWorker.register('/home/sw.js');
  }
};

// Intl polyfill
if (!global.Intl) {
  (async () => {
    try {
      await import('intl');

      await import('intl/locale-data/jsonp/pt-BR');
      import('intl/locale-data/jsonp/en');
    } catch (err) {
      // eslint-disable-next-line
      console.log('error loading intl polyfill: ', err);
    }

    runApp();
  })();
} else {
  runApp();
}
